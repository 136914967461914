<template>
  <div class="onlineData-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客服员工:</span>
        <el-select
          class="common-screen-input"
          v-model="roleSelect"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="roleSelect"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属部门:</span>
        <el-cascader
          class="common-screen-input"
          v-model="cascaderValue"
          :options="options1"
        ></el-cascader>
      </div>
      <br />
      <div class="common-input-container">
        <el-date-picker
          v-model="datePicker"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="radio1" @change="changeRadio">
          <el-radio-button :label="1">今天</el-radio-button>
          <el-radio-button :label="7">近7天</el-radio-button>
          <el-radio-button :label="30">近30天</el-radio-button>
          <el-radio-button :label="90">近90天</el-radio-button>
        </el-radio-group>
      </div>
      <el-button class="common-screen-btn" type="primary">查 询</el-button>
      <el-button class="common-screen-btn" plain>重 置</el-button>
    </div>
    <el-button class="common-screen-btn">导出数据表格</el-button>
    
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column prop="date" label="店铺名称" sortable="custom">
      </el-table-column>
      <el-table-column prop="name" label="咨询量" sortable="custom">
      </el-table-column>
      <el-table-column prop="address" label="接待量" sortable="custom">
      </el-table-column>
      <el-table-column prop="address" label="销售量" sortable="custom">
      </el-table-column>
      <el-table-column prop="address" label="销售额" sortable="custom">
      </el-table-column>
      <el-table-column prop="address" label="转化率" sortable="custom">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { getLatelyDay } from "../../utils/index.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "客服在班数据", isLink: false },
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
      ],
      cascaderValue: "",
      roleSelect: "",
      radio1: 1,
      datePicker: [new Date(), new Date()],
      options: [
        // 筛选下拉
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      options1: [
        {
          value: "daohang",
          label: "导航",
          children: [
            {
              value: "cexiangdaohang",
              label: "侧向导航",
            },
            {
              value: "dingbudaohang",
              label: "顶部导航",
            },
          ],
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    tableSort(e) {
      console.log(e);
    },
    changeRadio(e) {
      this.datePicker = getLatelyDay(e);
    },
  },
};
</script>
<style lang="less" scoped>
.onlineData-container {
  text-align: left;
  .onlineData-content {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }
}
</style>